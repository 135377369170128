import vueFormGenerator from 'vue-form-generator';
import validators from './city.validators';
import commonValidator from '@/services/helpers/validator.common';

export const model = {
	AutypeId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'autocomplete',
				typeSearch: '1',
				model: 'CityParentId',
				default: 0,
				i18n: {
					label: 'parentofcity3265',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameSk',
				validator: validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalSk',
				validator: validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameCz',
				validator: validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalCz',
				validator: validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNamePl',
				validator: validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalPl',
				validator: validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameEn',
				validator: validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalEn',
				validator: validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameDe',
				validator: validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalDe',
				validator: validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameHu',
				validator: validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityNameLokalHu',
				validator: validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityUrlName',
				required: true,
				validator: validators.cityUrlName,
				i18n: {
					label: 'urlname',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityZip',
				required: true,
				validator: commonValidator.checkEmpty,
				i18n: {
					label: 'zip',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CityZip',
				required: true,
				validator: commonValidator.checkEmpty,
				i18n: {
					label: 'zip',
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'TerritoryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'value',
					name: 'text',
				},
				i18n: {
					label: 'county3266',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'DistrictId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'value',
					name: 'text',
				},
				i18n: {
					label: 'district3266',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CityOrder',
				i18n: {
					label: 'positiononhomep',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CityGoogleMapsGpsLat',
				required: true,
				// validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CityGoogleMapsGpsLng',
				required: true,
				// validator: vueFormGenerator.validators.double,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CityGoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
