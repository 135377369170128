<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="pmanualforcitie"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofcity') }}
			<b-link target="_blank" :href="`${model.DomainUrl + model.CityUrlName}`">
				{{ model.CountryId === 1 ? model.CityNameSk : model.CityNameCz }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.CityId }}</label>
		</template>

		<template #beforeFormActions>
			<google-map
				:latitude="+model.CityGoogleMapsGpsLat"
				:longitude="+model.CityGoogleMapsGpsLng"
				:boundaries="model.CityBoundaries"
				:zoom="+model.CityGoogleMapsZoom"
				:onChanged="coordinatesChanged"
				height="700"
				:boundariesEnabled="false"
			></google-map>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import { model, fields } from './city.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'City',
			routeParam: 'CityId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		async loadResources() {
			const [territoryEnum, districtEnum] = await Promise.all([
				serviceDictionary.getTerritoryEnum(this.model.CountryId),
				serviceDictionary.getDistrictEnum(this.model.TerritoryId),
			]);
			const CountryValues = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: {
					values: CountryValues,
					onChanged: () => {
						if (this.model.CountryId) {
							serviceDictionary.getTerritoryEnum(this.model.CountryId).then((resp) => {
								this.updateSchemaFields({
									TerritoryId: { values: resp.territorySelectorItems },
									DistrictId: { values: [] },
								});
								this.updateFormModel({ TerritoryId: null, DistrictId: null });
							});
						}
					},
				},
				TerritoryId: {
					values: territoryEnum.territorySelectorItems,
					onChanged: () => {
						if (this.model.TerritoryId) {
							serviceDictionary.getDistrictEnum(this.model.TerritoryId).then((resp) => {
								this.updateSchemaFields({ DistrictId: { values: resp.districtSelectorItems } });
								this.updateFormModel({ DistrictId: null });
							});
						}
					},
				},
				DistrictId: {
					values: districtEnum.districtSelectorItems,
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.CityId, this.$modal, this.deleteModel);
		},

		coordinatesChanged(lat, lng, zoom, polygonPaths) {
			const modelUpdate = {};

			if (lat) {
				modelUpdate.CityGoogleMapsGpsLat = +lat;
				modelUpdate.CityGoogleMapsGpsLng = +lng;
			}
			if (zoom) {
				modelUpdate.CityGoogleMapsZoom = +zoom;
			}
			if (polygonPaths) {
				modelUpdate.CityBoundaries = JSON.stringify(polygonPaths);
			}

			this.updateFormModel(modelUpdate);
			this.$refs[this.detailPageRefName].validate();
		},
	},
};
</script>