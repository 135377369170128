import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '../../services/lang';

const validators = {
	async cityUrlName() {
		if (!this.model.CityUrlName || this.model.CityUrlName.isNullOrWhiteSpace()) {
			return [i18n.t('amistake!')];
		}

		if (
			!this.model.CityUrlName ||
			this.model.CityUrlName.isNullOrWhiteSpace() ||
			this.model.CityUrlName.indexOf(' ') >= 0
		) {
			return [i18n.t('amistake!')];
		}

		if (!this.model.CityUrlName || this.model.CityUrlName.withoutDiacritics() != this.model.CityUrlName) {
			return [i18n.t('amistake!')];
		}

		let resp = await http.get('City/ExistsUrl', {
			cityId: this.model.CityId,
			url: this.model.CityUrlName,
		});

		if (resp.data === true) {
			return [i18n.t('urlnamemustbeor')];
		}

		return [];
	},
	required() {
		switch (this.schema.model) {
			case 'CityNameSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.CityNameSk || this.model.CityNameSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'CityNameLokalSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.CityNameSk || this.model.CityNameLokalSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'CityNameCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.CityNameCz || this.model.CityNameCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'CityNameLokalCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.CityNameCz || !this.model.CityNameLokalCz)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'CityNamePl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.CityNamePl || this.model.CityNamePl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'CityNameLokalPl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.CityNamePl || !this.model.CityNameLokalPl)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
		}
	},
};

export default validators;
